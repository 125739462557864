import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  HashRouter,
  withRouter,
  useHistory,
  useLocation,
} from "react-router-dom";

import logo from "./logo.svg";
import "semantic-ui-css/semantic.min.css";
import "./App.scss";
import Login from "./pages/Login/Login";
import Signup from "./pages/Login/Signup";
import Dashboard from "./pages/Dashboard/Dashboard";
import apiServices from "./Services/apiServices";
import WithAuth from "./components/WithAuth";
import { Result } from "antd";

function NoMatch() {
  let location = useLocation();

  return (
    <div>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        // extra={<Button type='primary'>Back Home</Button>}
      />
    </div>
  );
}

function App() {
  return (
    <HashRouter>
      <div className="App">
        <Switch>
          <Route exact path="/">
            <Login />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/signup">
            <Signup />
          </Route>
          <Route path="/dashboard">
            <WithAuth>
              <Dashboard />
            </WithAuth>
          </Route>
          <Route path="*">
            <NoMatch />
          </Route>
        </Switch>
      </div>
    </HashRouter>
  );
}

export default App;
