import { Button, Divider, Input, notification } from "antd";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import apiServices from "../../../../Services/apiServices";

import "./UpdateChurch.scss";

export default withRouter(function UpdateChurch({ history }) {
  let user = JSON.parse(localStorage.getItem("user"));
  let roleID = JSON.parse(localStorage.getItem("current_role"));
  let afm_churches = JSON.parse(localStorage.getItem("afm_churches"));
  const [myChrch, setmyChrch] = useState("");
  const allowedRoles = {
    w: "weca",
    r: "regions",
    d: "districts",
    z: "zones",
    b: "branches",
  };

  let currentRole = localStorage.getItem("current_role");
  const [isBranch, setisBranch] = useState(true); // b === branch, z === zonal
  const [loading, setLoading] = useState(false);
  const [pastorInfo, setPastorInfo] = useState({
    name: "",
    phoneNumber: "",
    email: "",
  });

  const [overseerInfo, setOverseerInfo] = useState({
    leader: {
      name: "",
      phoneNumber: "",
      email: "",
    },
    secretary: {
      name: "",
      phoneNumber: "",
      email: "",
    },
  });

  useEffect(() => {
    let myRole = currentRole
      ? JSON.parse(currentRole)
          .officeID.replaceAll("1", "")
          .replaceAll("2", "")
          .replaceAll("3", "")
          .replaceAll("4", "")
          .replaceAll("5", "")
          .replaceAll("6", "")
          .replaceAll("7", "")
          .replaceAll("8", "")
          .replaceAll("9", "")
      : null;
    let isBranch_ = myRole === "b" || myRole === "z";
    setisBranch(isBranch_);
    let levelChrchs = afm_churches[allowedRoles[myRole]];
    let myChrch = roleID.officeID.includes("w")
      ? afm_churches.regions?.find((c) => c.name === "Anthony").churchId
      : levelChrchs?.find((c) => c.name === roleID.levelName.replaceAll("%20", " ")).churchId;
    setmyChrch(myChrch);
  }, []);

  const openNotificationWithIcon = (type, description) => {
    notification[type]({
      message: type.toUpperCase(),
      description,
    });
  };

  const updateBranchData = () => {
    let data = {
      leader: pastorInfo,
      churchId: myChrch,
    };
    setLoading(true);
    apiServices
      .updateBranchInfo(data)
      .then((res) => {
        openNotificationWithIcon("success", "Church info successfully updated");
        fetchChurches();
        history.push("/dashboard");
      })
      .catch((err) => {
        console.log({ err });
      })
      .finally(() => setLoading(false));
  };

  const updateOverseerData = () => {
    let data = {
      leader: overseerInfo,
      churchId: myChrch,
    };
    setLoading(true);
    apiServices
      .updateOverseerInfo(data)
      .then((res) => {
        openNotificationWithIcon("success", "Church info successfully updated");
        fetchChurches();
        history.push("/dashboard");
      })
      .catch((err) => {
        console.log({ err });
      })
      .finally(() => setLoading(false));
  };

  const fetchChurches = () => {
    apiServices
      .fetchChurches_()
      .then((res) => {
        let branches = res.data.data.branches
          .map((z) => Object.values(z)[0])
          .reduce((a, b) => a.concat(b), [])
          .map((b) => {
            return {
              ...b,
              label: b.name,
              value: b.churchId,
            };
          });

        let zones = res.data.data.zones
          .map((z) => {
            return Object.values(z)[0];
          })
          .reduce((a, b) => a.concat(b), [])
          .map((zz) => {
            return {
              ...zz,
              label: zz.name,
              value: zz.name,
            };
          });

        let districts = res.data.data.districts
          .map((z) => Object.values(z)[0])
          .reduce((a, b) => a.concat(b), [])
          .map((b) => {
            return {
              ...b,
              label: b.name,
              value: b.name,
            };
          });

        let regions = res.data.data.regions.map((r) => {
          return {
            ...r,
            label: r.name,
            value: r.name,
          };
        });
        let payload = {
          branches,
          zones,
          districts,
          regions,
        };
        localStorage.setItem("afm_churches", JSON.stringify(payload));
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  return (
    <div style={{ padding: 15 }}>
      <div className="update-chrch shadowed">
        <p className="title">Update Church Information</p>
        {isBranch ? (
          <>
            <p className="label">
              {" "}
              <span>
                {roleID.levelName.replaceAll("%20", " ")} {roleID.level_}{" "}
              </span>
              Pastor's name
            </p>
            <Input
              onChange={(e) =>
                setPastorInfo({
                  ...pastorInfo,
                  name: e.target.value,
                })
              }
            />

            <p className="label">
              <span>
                {roleID.levelName.replaceAll("%20", " ")} {roleID.level_}{" "}
              </span>
              Pastor's phone number
            </p>
            <Input
              onChange={(e) =>
                setPastorInfo({
                  ...pastorInfo,
                  phoneNumber: e.target.value,
                })
              }
            />

            <p className="label">
              <span>
                {roleID.levelName.replaceAll("%20", " ")} {roleID.level_}{" "}
              </span>
              Pastor's email
            </p>
            <Input
              onChange={(e) =>
                setPastorInfo({
                  ...pastorInfo,
                  email: e.target.value,
                })
              }
            />
          </>
        ) : (
          <>
            <p className="label">
              <span>
                {roleID.levelName.replaceAll("%20", " ")}{" "}
                {roleID.level_.toLowerCase() == "weca"
                  ? ""
                  : roleID.level_.toLowerCase() == "region"
                  ? "Regional"
                  : roleID.level_}{" "}
              </span>
              Overseer's name
            </p>
            <Input
              onChange={(e) =>
                setOverseerInfo({
                  ...overseerInfo,
                  regionalOverseer: {
                    ...overseerInfo.regionalOverseer,
                    name: e.target.value,
                  },
                })
              }
            />

            <p className="label">
              <span>
                {roleID.levelName.replaceAll("%20", " ")}{" "}
                {roleID.level_.toLowerCase() == "weca"
                  ? ""
                  : roleID.level_.toLowerCase() == "region"
                  ? "Regional"
                  : roleID.level_}{" "}
              </span>
              Overseer's phone number
            </p>
            <Input
              onChange={(e) =>
                setOverseerInfo({
                  ...overseerInfo,
                  regionalOverseer: {
                    ...overseerInfo.regionalOverseer,
                    phoneNumber: e.target.value,
                  },
                })
              }
            />

            <p className="label">
              <span>
                {roleID.levelName.replaceAll("%20", " ")}{" "}
                {roleID.level_.toLowerCase() == "weca"
                  ? ""
                  : roleID.level_.toLowerCase() == "region"
                  ? "Regional"
                  : roleID.level_}{" "}
              </span>
              Overseer's email
            </p>
            <Input
              onChange={(e) =>
                setOverseerInfo({
                  ...overseerInfo,
                  regionalOverseer: {
                    ...overseerInfo.regionalOverseer,
                    email: e.target.value,
                  },
                })
              }
            />

            <Divider />

            <p className="label">
              <span>
                {roleID.levelName.replaceAll("%20", " ")}{" "}
                {roleID.level_.toLowerCase() == "weca"
                  ? ""
                  : roleID.level_.toLowerCase() == "region"
                  ? "Regional"
                  : roleID.level_}{" "}
              </span>
              Overseer's secretary's name
            </p>
            <Input
              onChange={(e) =>
                setOverseerInfo({
                  ...overseerInfo,
                  regionalOverseerSecretary: {
                    ...overseerInfo.regionalOverseerSecretary,
                    name: e.target.value,
                  },
                })
              }
            />

            <p className="label">
              <span>
                {roleID.levelName.replaceAll("%20", " ")}{" "}
                {roleID.level_.toLowerCase() == "weca"
                  ? ""
                  : roleID.level_.toLowerCase() == "region"
                  ? "Regional"
                  : roleID.level_}{" "}
              </span>
              Overseer's secretary's phone number
            </p>
            <Input
              onChange={(e) =>
                setOverseerInfo({
                  ...overseerInfo,
                  regionalOverseerSecretary: {
                    ...overseerInfo.regionalOverseerSecretary,
                    phoneNumber: e.target.value,
                  },
                })
              }
            />

            <p className="label">
              <span>
                {roleID.levelName.replaceAll("%20", " ")}{" "}
                {roleID.level_.toLowerCase() === "weca"
                  ? ""
                  : roleID.level_.toLowerCase() == "region"
                  ? "Regional"
                  : roleID.level_}{" "}
              </span>
              Overseer's secretary's email
            </p>
            <Input
              onChange={(e) =>
                setOverseerInfo({
                  ...overseerInfo,
                  regionalOverseerSecretary: {
                    ...overseerInfo.regionalOverseerSecretary,
                    email: e.target.value,
                  },
                })
              }
            />
          </>
        )}

        <Button
          type="primary"
          style={{ marginTop: 15 }}
          loading={loading}
          onClick={isBranch ? updateBranchData : updateOverseerData}
        >
          Submit
        </Button>
      </div>
    </div>
  );
});
