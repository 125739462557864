import React, { useEffect, useState } from "react";
import { Row, Col, Carousel, Table, Select, Button, Tag, Modal, Form, Input, notification, Empty } from "antd";
import "./home.scss";
import {
  FileFilled,
  FlagFilled,
  PrinterFilled,
  FileImageFilled,
  VideoCameraFilled,
  AudioFilled,
  DownloadOutlined,
} from "@ant-design/icons";
import Axios from "axios";

// files
import zonalHod from "../../../../assets/YDD Job Profiles/Zonal JD's/Zonal HoD C&E.docx";
import zonalSecretariatHead from "../../../../assets/YDD Job Profiles/Zonal JD's/Zonal Secretariat Head.docx";
import zonalYouthLeader from "../../../../assets/YDD Job Profiles/Zonal JD's/Zonal Youth Leader - V0.docx";

import branchHod from "../../../../assets/YDD Job Profiles/Branch JD's/Branch HoD C&E.docx";
import branchSecretariatHead from "../../../../assets/YDD Job Profiles/Branch JD's/Branch Secretariat Head.docx";
import branchYouthLeader from "../../../../assets/YDD Job Profiles/Branch JD's/Branch Youth Leader - V0.docx";

import regionalHodSecretariat from "../../../../assets/YDD Job Profiles/Regional JD's/Regional HOD_Secretariat_JP.docx";
import regionalHodSnP from "../../../../assets/YDD Job Profiles/Regional JD's/Regional HOD_SP_JP.docx";
import regionalOutreachHead from "../../../../assets/YDD Job Profiles/Regional JD's/Regional Outreach Head.docx";
import regionalYouthHead from "../../../../assets/YDD Job Profiles/Regional JD's/Regional Youth Head.docx";
import regionalYouthLeaderCnE from "../../../../assets/YDD Job Profiles/Regional JD's/Regional Youth Leader C&E.docx";
import regionalYouthLeader from "../../../../assets/YDD Job Profiles/Regional JD's/Regional Youth Leader.docx";

import districtCnE from "../../../../assets/YDD Job Profiles/Districts JD's/District Care & Empowerment.docx";
import districtHODSecretariat from "../../../../assets/YDD Job Profiles/Districts JD's/District HOD_Secretariat_JP.docx";
import districtOutreachHead from "../../../../assets/YDD Job Profiles/Districts JD's/District Outreach Head.docx";
import districtAFCFPresident from "../../../../assets/YDD Job Profiles/Districts JD's/District President_AFCF_JOB PROFILE.docx";
import districtAFCMFPresident from "../../../../assets/YDD Job Profiles/Districts JD's/DISTRICT PRESIDENT_AFCMF.doc";
import districtAFCFSecretary from "../../../../assets/YDD Job Profiles/Districts JD's/District Secretary_AFCF.docx";
import districtAFCMFSecretary from "../../../../assets/YDD Job Profiles/Districts JD's/DISTRICT SECRETARY_AFCMF.doc";
import districtYouthHead from "../../../../assets/YDD Job Profiles/Districts JD's/District Youth Head.docx";
import districtYouthLeader from "../../../../assets/YDD Job Profiles/Districts JD's/District Youth Leader 1.docx";
import { Link } from "react-router-dom";

const { Option } = Select;

function DashboardHome(props) {
  const linkLists = [
    // {
    //   title: "YLCB 2020 - audio",
    //   type: "audio",
    //   items: [
    //     "http://youth.afmweca.com/ydd-photos/img_sermon214.jpg",
    //     "http://youth.afmweca.com/ydd-photos/img_sermon214.jpg",
    //     "http://youth.afmweca.com/ydd-photos/img_sermon214.jpg",
    //     "http://youth.afmweca.com/ydd-photos/img_sermon214.jpg",
    //     "http://youth.afmweca.com/ydd-photos/img_sermon214.jpg",
    //   ],
    // },
    {
      title: "YLCB 2020 - pictures",
      type: "pictures",
      items: [
        "http://youth.afmweca.com/ydd-photos/img_sermon214.jpg",
        "http://youth.afmweca.com/ydd-photos/img_sermon214.jpg",
        "http://youth.afmweca.com/ydd-photos/img_sermon214.jpg",
        "http://youth.afmweca.com/ydd-photos/img_sermon214.jpg",
        "http://youth.afmweca.com/ydd-photos/img_sermon214.jpg",
      ],
    },
    {
      title: "YLCB 2021 - videos",
      type: "videos",
      items: [
        "https://www.youtube.com/embed/ilQIwTkvsMw",
        "https://www.youtube.com/embed/ilQIwTkvsMw",
        "https://www.youtube.com/embed/ilQIwTkvsMw",
        "https://www.youtube.com/embed/ilQIwTkvsMw",
        "https://www.youtube.com/embed/ilQIwTkvsMw",
      ],
    },
    {
      title: "YLCB 2021 - videos",
      type: "videos",
      items: [
        "https://www.youtube.com/embed/ilQIwTkvsMw",
        "https://www.youtube.com/embed/ilQIwTkvsMw",
        "https://www.youtube.com/embed/ilQIwTkvsMw",
        "https://www.youtube.com/embed/ilQIwTkvsMw",
        "https://www.youtube.com/embed/ilQIwTkvsMw",
      ],
    },
    {
      title: "YLCB 2020 - pictures",
      type: "pictures",
      items: [
        "http://youth.afmweca.com/ydd-photos/img_sermon214.jpg",
        "http://youth.afmweca.com/ydd-photos/img_sermon214.jpg",
        "http://youth.afmweca.com/ydd-photos/img_sermon214.jpg",
        "http://youth.afmweca.com/ydd-photos/img_sermon214.jpg",
        "http://youth.afmweca.com/ydd-photos/img_sermon214.jpg",
      ],
    },
    {
      title: "YLCB 2021 - videos",
      type: "video",
      items: [
        "https://www.youtube.com/embed/ilQIwTkvsMw",
        "https://www.youtube.com/embed/ilQIwTkvsMw",
        "https://www.youtube.com/embed/ilQIwTkvsMw",
        "https://www.youtube.com/embed/ilQIwTkvsMw",
        "https://www.youtube.com/embed/ilQIwTkvsMw",
      ],
    },
    {
      title: "YLCB 2021 - pictures",
      type: "pictures",
      items: [
        "http://youth.afmweca.com/ydd-photos/img_sermon214.jpg",
        "http://youth.afmweca.com/ydd-photos/img_sermon214.jpg",
        "http://youth.afmweca.com/ydd-photos/img_sermon214.jpg",
        "http://youth.afmweca.com/ydd-photos/img_sermon214.jpg",
        "http://youth.afmweca.com/ydd-photos/img_sermon214.jpg",
      ],
    },
    {
      title: "YLCB 2021 - pictures",
      type: "pictures",
      items: [
        "http://youth.afmweca.com/ydd-photos/img_sermon214.jpg",
        "http://youth.afmweca.com/ydd-photos/img_sermon214.jpg",
        "http://youth.afmweca.com/ydd-photos/img_sermon214.jpg",
        "http://youth.afmweca.com/ydd-photos/img_sermon214.jpg",
        "http://youth.afmweca.com/ydd-photos/img_sermon214.jpg",
      ],
    },
    {
      title: "YLCB 2021 - pictures",
      type: "pictures",
      items: [
        "http://youth.afmweca.com/ydd-photos/img_sermon214.jpg",
        "http://youth.afmweca.com/ydd-photos/img_sermon214.jpg",
        "http://youth.afmweca.com/ydd-photos/img_sermon214.jpg",
        "http://youth.afmweca.com/ydd-photos/img_sermon214.jpg",
        "http://youth.afmweca.com/ydd-photos/img_sermon214.jpg",
      ],
    },
  ];
  const dataSource = [
    // {
    //   key: "1",
    //   name: "Youth Leaders Cpapcity Building",
    //   type: "youth",
    //   date: "12th April 2022",
    // },
  ];
  const JDs = [
    {
      title: "Branch Hod-Care & Empowerment",
      file: branchHod,
    },
    {
      title: "Branch Secretariat Head",
      file: branchSecretariatHead,
    },
    {
      title: "Branch Youth Leader",
      file: branchYouthLeader,
    },
    {
      title: "Zonal HOD",
      file: zonalHod,
    },
    {
      title: "Zonal Secretariat Head",
      file: zonalSecretariatHead,
    },
    {
      title: "Zonal Youth Leader",
      file: zonalYouthLeader,
    },
    {
      title: "Regional Hod Secretariat",
      file: regionalHodSecretariat,
    },
    {
      title: "Regional Hod S&P",
      file: regionalHodSnP,
    },
    {
      title: "Regional Outreach Head",
      file: regionalOutreachHead,
    },
    {
      title: "Regional Youth Head",
      file: regionalYouthHead,
    },
    {
      title: "Regional Youth Leader - Care & Empowerment",
      file: regionalYouthLeaderCnE,
    },
    {
      title: "Regional Youth Leader",
      file: regionalYouthLeader,
    },
    {
      title: "District - Care & Empowerment",
      file: districtCnE,
    },
    {
      title: "District HOD Secretariat",
      file: districtHODSecretariat,
    },
    {
      title: "District Outreach Head",
      file: districtOutreachHead,
    },
    {
      title: "District AFCF President",
      file: districtAFCFPresident,
    },
    {
      title: "District AFCMF President",
      file: districtAFCMFPresident,
    },
    {
      title: "District AFCF Secretary",
      file: districtAFCFSecretary,
    },
    {
      title: "District AFCMF Secretary",
      file: districtAFCMFSecretary,
    },
    {
      title: "District Youth Head",
      file: districtYouthHead,
    },
    {
      title: "District Youth Leader",
      file: districtYouthLeader,
    },
  ];

  const columns = [
    {
      title: "Programme Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Programme Type",
      dataIndex: "type",
      key: "type",
      render: (type) => <Tag color="green">{type}</Tag>,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Actions",
      dataIndex: "date",
      key: "date",
      render: () => (
        <>
          {/* <Button
            type="primary"
            onClick={() => {
              console.log("lol");
              setOpenModal(true);
              setForOthers(false);
            }}
          >
            Register for YLCB
          </Button> */}
        </>
      ),
    },
  ];
  const [form] = Form.useForm();
  const [currentList, setCurrentList] = useState(linkLists[0]);
  const [currentItm, setCurrentItm] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [forOthers, setForOthers] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clusters, setClusters] = useState([]);
  const [district, setDistrict] = useState({});
  const [user, setUser] = useState({});
  const [churches, setChurches] = useState({
    branches: [],
    districts: [],
    regions: [],
    zones: [],
  });

  useEffect(() => {
    let chrch = localStorage.getItem("afm_churches");
    let user = JSON.parse(localStorage.getItem("user"));
    user && setUser(user);
    if (chrch) {
      let initial = JSON.parse(chrch);
      let regions_ = initial.regions.map((region) => {
        return {
          ...region,
          childCount: initial.districts.filter((d) => d.parent.name === region.name).length,
        };
      });

      let districts_ = initial.districts.map((district) => {
        return {
          ...district,
          childCount: initial.zones.filter((d) => d.parent.name === district.name).length,
        };
      });

      let zones_ = initial.zones.map((zone) => {
        return {
          ...zone,
          childCount: initial.branches.filter((d) => d.zone === zone.name).length,
        };
      });
      let final = {
        regions: regions_,
        districts: districts_,
        zones: zones_,
        branches: initial.branches,
      };
      setChurches(final);
    }

    Axios({
      method: "get",
      url: "https://afmpass.name.ng/afmconfig/api/v1/config/resources/clusters",
    })
      .then((res) => {
        setClusters(res.data.data);
      })
      .catch((err) => {
        throw new Error(err);
        //console.log(err);
      });
  }, []);

  const submit = () => {
    let data = {
      ...form.getFieldsValue(true),
      email: forOthers ? form.getFieldsValue(true).email : user.email,
    };
    !forOthers && delete data["churchOrFellowshipDuty"];
    let payload = {
      email: data.email,
      clusterAttended: {
        district: data.district,
        cluster: data.cluster,
      },
    };
    if (forOthers) {
      payload["churchOrFellowshipDuty"] = data["churchOrFellowshipDuty"];
    }
    setLoading(true);
    Axios({
      method: "post",
      url: "https://afmpass.name.ng/afmylcb/api/v1/ylcb/register",
      data: payload,
    })
      .then((res) => {
        notification.success({
          message: "User registered successfully",
        });
        setOpenModal(false);
      })
      .catch((err) => {
        console.log(err.response);
        notification.error({
          message: err.response.data.message || "An error occured. Please try again",
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="home-page">
      <Row gutter={20}>
        <Col sm={24} md={8}>
          <div className="cardd">
            <p style={{ fontSize: 18 }}>New Files</p>

            <div className="flex-row">
              <div className="big-text">0</div>
              <div className="big-text">
                <PrinterFilled />
              </div>
            </div>
          </div>
        </Col>
        <Col sm={24} md={8}>
          <div className="cardd">
            <p style={{ fontSize: 18 }}>Reports</p>

            <div className="flex-row">
              <div className="big-text">0</div>
              <div className="big-text">
                <FileFilled />
              </div>
            </div>
          </div>
        </Col>
        <Col sm={24} md={8}>
          <div className="cardd">
            <p style={{ fontSize: 18 }}>Pending Tasks </p>

            <div className="flex-row">
              <div className="big-text">0</div>
              <div className="big-text">
                <FlagFilled />
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Row gutter={20} style={{ marginTop: 30 }}>
        <Col sm={24} md={12}>
          <div className="link-lists shadowed">
            <Empty />
          </div>
          {/* <Carousel autoplay={currentList.type == "pictures"}>
            {currentList.items.map((itm) => {
              return (
                <div className="container-sty">
                  {currentList.type === "pictures" ? (
                    <img
                      src="http://youth.afmweca.com/ydd-photos/img_sermon214.jpg"
                      alt=""
                      style={{
                        width: "100%",
                      }}
                    />
                  ) : (
                    <iframe
                      src={itm}
                      style={{ width: "100%", height: "100%" }}
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  )}
                </div>
              );
            })}
          </Carousel> */}
        </Col>
        <Col sm={24} md={12}>
          <div className="link-lists shadowed">
            <div className="top">RECENT YDD MEDIA</div>
            <Empty />
            {/* <div className="links-wrap">
              {linkLists.map((link, i) => {
                return (
                  <div
                    className={`single-item ${i == currentItm ? "current" : ""}`}
                    onClick={() => {
                      setCurrentList(link);
                      setCurrentItm(i);
                    }}
                  >
                    <span className="icn">
                      {link.type === "videos" ? (
                        <VideoCameraFilled />
                      ) : link.type === "pictures" ? (
                        <FileImageFilled />
                      ) : (
                        <AudioFilled />
                      )}
                    </span>

                    <span style={{ marginLeft: 20 }}>{link.title}</span>
                  </div>
                );
              })}
            </div> */}
          </div>
        </Col>
      </Row>

      <Row gutter={20} style={{ marginTop: 30 }}>
        <Col sm={24} md={16}>
          <div className="shadowed" style={{ padding: 20 }}>
            <div
              style={{
                marginBottom: 40,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <h3>Program Data</h3>

              <Select style={{ width: 300 }}>
                <Option value="upcoming">Upcoming programs</Option>
              </Select>
            </div>
            <Table dataSource={dataSource} columns={columns} />
          </div>
        </Col>
        <Col sm={24} md={8}>
          <div className="link-lists shadowed">
            <div className="top">Leaders job description</div>
            <div className="links-wrap">
              {JDs.map((itm) => (
                <a href={itm.file} download={`${itm.title}.docx`}>
                  <div className="single-item" style={{ fontSize: 16 }}>
                    <DownloadOutlined style={{ marginRight: 12 }} /> {itm.title}
                  </div>
                </a>
              ))}
            </div>
          </div>
        </Col>
      </Row>

      <Modal
        title="Register for YLCB 2022"
        visible={openModal}
        onCancel={() => setOpenModal(false)}
        onOk={submit}
        confirmLoading={loading}
      >
        <Row>
          <Button
            style={{ float: "right" }}
            type="primary"
            onClick={() => {
              setForOthers(!forOthers);
            }}
          >
            {!forOthers ? "Fill for others" : "Fill for yourself"}
          </Button>{" "}
        </Row>
        <br />
        <Form form={form} layout="vertical">
          {forOthers && (
            <>
              <Form.Item name="email" label="Email address">
                <Input size="large" />
              </Form.Item>
              <Form.Item name="churchOrFellowshipDuty" label="Church/Fellowship Duty">
                <Input size="large" />
              </Form.Item>
            </>
          )}
          <Form.Item name="district" label="District">
            <Select
              size="large"
              onChange={(e) => {
                setDistrict(e);
              }}
            >
              {churches.districts.map((district) => (
                <Option value={district.value}>{district.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="cluster" label="Cluster">
            <Select size="large">
              {clusters
                .find((c) => c.district === district)
                ?.cluster?.map((value) => (
                  <Option value={value}>{value}</Option>
                ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default DashboardHome;
