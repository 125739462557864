import React from "react";
import "./Login.scss";

import people from "../../assets/people.png";
import { withRouter } from "react-router-dom";

export default withRouter(function Signup({ history }) {
  const toLogin = () => {
    history.push("/login");
  };
  return (
    <div className="login">
      <div className="half">
        <p className="title">The Apostolic Faith Youth Directorate Portal</p>
        <div className="form">
          <p className="welcome">It's good to have you join the platform...</p>
          <div className="inp">
            <span className="label">Email Address</span>
            <input type="text" />
          </div>
          <div className="inp">
            <span className="label">Password</span>
            <input type="text" />
          </div>
          <div className="inp">
            <span className="label">Email Address</span>
            <input type="text" />
          </div>
          <div className="inp">
            <span className="label">Password</span>
            <input type="text" />
          </div>
          <p className="forgot">forgot password?</p>

          <button className="main-btn">Update</button>
          <p className="caveat pointer" onClick={toLogin}>
            Already have an account? Login
          </p>
        </div>
      </div>
      <div className="half people-wrap">
        <img src={people} className="people" alt="" />
      </div>
    </div>
  );
});
