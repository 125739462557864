import React, { useState } from "react";
import "./Login.scss";

import people from "../../assets/people.png";
import { withRouter } from "react-router-dom";
import apiServices from "../../Services/apiServices";
import { Button, notification } from "antd";
import { EyeOutlined } from "@ant-design/icons";

export default withRouter(function Login({ history }) {
  const [loading, setLoading] = useState(false);
  const [loginData, setloginData] = useState({
    username: "",
    password: "",
  });
  const [show, setshow] = useState(false);

  const toSignup = () => {
    history.push("/signup");
  };
  const toDash = () => {
    history.push("/dashboard");
  };

  const openNotificationWithIcon = (type, description) => {
    notification[type]({
      message: type.toUpperCase(),
      description,
    });
  };

  const loginHandler = () => {
    if (!loginData.username || !loginData.password) {
      return;
    }
    setLoading(true);
    apiServices
      .userLogin(loginData)
      .then((res) => {
        if (!res.data.data) {
          openNotificationWithIcon("error", "Invalid login details");
          return;
        }
        localStorage.setItem("user", JSON.stringify(res.data.data.user));
        let roles = res.data.data.user.roleId;
        localStorage.setItem(
          "current_role",
          JSON.stringify(res.data.data.user)
        );
        localStorage.setItem("afm_token", res.data.data.token);
        if (roles && roles.includes("[") && JSON.parse(roles).length) {
          let currentRole = JSON.parse(roles)[0];
          localStorage.setItem("current_role", JSON.stringify(currentRole));
          toDash();
        } else {
          openNotificationWithIcon(
            "error",
            "You have not been assigned to a role. Kindly check back later"
          );
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  const keyUp = (e) => {
    if (e.keyCode === 13 || e.key === "Enter") {
      loginHandler();
    }
  };

  return (
    <div className="login">
      <div className="half">
        <p className="title">The Apostolic Faith Youth Directorate Portal</p>
        <div className="form">
          <p className="welcome">Welcome, please login to your account</p>
          <div className="inp">
            <span className="label">Email Address/Username</span>
            <input
              onChange={(e) =>
                setloginData({ ...loginData, username: e.target.value })
              }
              type="text"
              onKeyUp={keyUp}
            />
          </div>
          <div className="inp">
            <span className="label">Password</span>
            <input
              type={show ? "text" : "password"}
              onKeyUp={keyUp}
              onChange={(e) =>
                setloginData({ ...loginData, password: e.target.value })
              }
            />
            <EyeOutlined className="eye" onClick={() => setshow(!show)} />
          </div>
          <p className="forgot">
            <a href="http://youth.afmweca.com/signup" target="_blank">
              <span>Dont have an account? signup</span>
            </a>
            <a href="http://youth.afmweca.com/forgot-password" target="_blank">
              <span>forgot password?</span>
            </a>
          </p>

          <Button className="main-btn" onClick={loginHandler} loading={loading}>
            Login now
          </Button>
          {/* <p className="caveat pointer" onClick={toSignup}>
            Do not have an account? Register
          </p> */}
        </div>
      </div>
      <div className="half people-wrap">
        <img src={people} className="people" alt="" />
      </div>
    </div>
  );
});
