import React, { useEffect } from "react";
import { withRouter } from "react-router";

export default withRouter(function WithAuth({ history, children }) {
  useEffect(() => {
    let token = localStorage.getItem("afm_token");
    if (!token) {
      history.push("/login");
      console.log("no token");
    }
  }, []);
  return <div>{children}</div>;
});
