import React, { useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import "./Library.scss";

const Library = () => {
  const [isLoading, setIsLoading] = useState(true);

  const iframeLoaded = () => setIsLoading(false);

  const level = JSON.parse(localStorage.getItem("current_role"))["levelName"];

  if (level === "weca" || level === "weca_admin") {
    return (
      <div>
        <iframe
          title="Library"
          onLoad={iframeLoaded}
          src="https://ydd_portal.surge.sh/"
          frameBorder="0"
        />
      </div>
    );
  }

  return (
    <div>
      { (
      <iframe
        title="Library"
        onLoad={iframeLoaded}
        src="https://ydd-portal-general.surge.sh/"
        frameBorder="0"
      />)}
    </div>
  );
};

export default Library;
