import React, { useEffect, useState } from "react";

import { Col, Row, Input, Select, Button, Switch } from "antd";
import { EditOutlined, PlusCircleFilled } from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";
import { Link, useLocation } from "react-router-dom";

const { Option } = Select;

export default function LevelTable({ level, roles, currentKey }) {
  let location = useLocation();
  const regionFields = [
    "Name",
    "No of districts",
    "Youth head",
    "Active users",
    "Actions",
  ];
  const districtFields = [
    "Name",
    "No of zones",
    "Youth head",
    "Active users",
    "Actions",
  ];
  const zoneFields = [
    "Name",
    "No of branches",
    "Youth head",
    "Active users",
    "Actions",
  ];
  const branchFields = ["Name", "Youth head", "Active users", "Actions"];

  const [region, setRegion] = useState("Anthony");
  const [district, setDistrict] = useState("");
  const [zone, setZone] = useState("");
  const [branch, setBranch] = useState("");
  const level_ =
    level === "zonal" ? "zone" : level === "regional" ? "region" : level;

  const [churches, setChurches] = useState({
    branches: [],
    districts: [],
    regions: [],
    zones: [],
  });

  const [headingsToUse, setHeadingsToUse] = useState(regionFields);
  const [dataToUse, setDataToUse] = useState(churches.regions);
  const [assignedMembers, setAssignedMembers] = useState([]);
  const [members, setMembers] = useState([]);
  const [myCurrentRole, setmyCurrentRole] = useState({
    region: null,
    district: null,
    zone: null,
    branch: null,
  });
  let currentRole = JSON.parse(localStorage.getItem("current_role"));

  useEffect(() => {
    let chrch = localStorage.getItem("afm_churches");
    if (chrch) {
      let initial = JSON.parse(chrch);
      let regions_ = initial.regions.map((region) => {
        return {
          ...region,
          childCount: initial.districts.filter(
            (d) => d.parent.name === region.name
          ).length,
        };
      });

      let districts_ = initial.districts.map((district) => {
        return {
          ...district,
          childCount: initial.zones.filter(
            (d) => d.parent.name === district.name
          ).length,
        };
      });

      let zones_ = initial.zones.map((zone) => {
        return {
          ...zone,
          childCount: initial.branches.filter((d) => d.zone === zone.name)
            .length,
        };
      });
      let final = {
        regions: regions_,
        districts: districts_,
        zones: zones_,
        branches: initial.branches,
      };

      setChurches(final);
      let branchDrillDown = {
        region: null,
        district: null,
        zone: null,
        branch: null,
      };
      if (currentRole.level_ === "branch") {
        let myBranch = final.branches.find(
          (b) => b.name === currentRole.levelName.replaceAll("%20", " ")
        );

        let myZone = myBranch.zone;
        let myDistrict = final.zones.find((z) => z.name === myZone).parent.name;
        let myRegion = final.districts.find((d) => d.name === myDistrict).parent
          .name;
        branchDrillDown = {
          branch: currentRole.levelName.replaceAll("%20", " "),
          zone: myZone,
          district: myDistrict,
          region: myRegion,
        };
      } else if (currentRole.level_ === "zone") {
        let myZone = final.zones.find(
          (z) => z.name === currentRole.levelName.replaceAll("%20", " ")
        ).name;
        let myDistrict = final.zones.find((z) => z.name === myZone).parent.name;
        let myRegion = final.districts.find((d) => d.name === myDistrict).parent
          .name;
        branchDrillDown = {
          branch: null,
          zone: myZone,
          district: myDistrict,
          region: myRegion,
        };
      } else if (currentRole.level_ === "district") {
        let myDistrict = final.districts.find(
          (d) => d.name === currentRole.levelName.replaceAll("%20", " ")
        ).parent.name;
        let myRegion = final.districts.find((d) => d.name === myDistrict).parent
          .name;

        branchDrillDown = {
          branch: null,
          zone: null,
          district: myDistrict,
          region: myRegion,
        };
      } else if (currentRole.level_ === "region") {
        let myRegion = final.regions.find(
          (r) => r.name === currentRole.levelName.replaceAll("%20", " ")
        ).name;
        branchDrillDown = {
          branch: null,
          zone: null,
          district: null,
          region: myRegion,
        };
      } else {
        branchDrillDown = {
          branch: null,
          zone: null,
          district: null,
          region: region || "Anthony",
        };
      }
      setmyCurrentRole(branchDrillDown);

      let header =
        level === "region"
          ? regionFields
          : level === "district"
          ? districtFields
          : level === "zonal"
          ? zoneFields
          : branchFields;
      setHeadingsToUse(header);
      let dataToUse =
        level === "regional"
          ? final.regions
          : level === "district"
          ? final.districts.filter(
              (d) => d.parent.name === branchDrillDown.region
            )
          : level === "zonal"
          ? final.zones.filter((d) => d.parent.name === district)
          : final.branches.filter((d) => d.zone === zone);

      setDataToUse(dataToUse);
    }
  }, [level, region, district, zone]);

  useEffect(() => {
    let chrch = localStorage.getItem("afm_churches");
    let members = localStorage.getItem("afm_members");
    chrch && setChurches(JSON.parse(chrch));
    let relevantRoleArr = [];
    if (members) {
      setMembers(JSON.parse(members));
      let assignedUsers = JSON.parse(members)
        .map((user) => {
          return {
            ...user,
            roleInfo:
              user.roleId && user.roleId.includes("[")
                ? JSON.parse(user.roleId)
                : null,
          };
        })
        .filter(
          (u) =>
            u.roleInfo &&
            u.roleId.includes("[") &&
            u.roleInfo.find((lv) => lv.level_ === level_)
        )
        .map((usr) => {
          return {
            ...usr,
            relevantRole: usr.roleInfo.filter((lv) => lv.level_ === level_),
          };
        });

      assignedUsers.map((user) => {
        user.relevantRole.map((role) => {
          relevantRoleArr.push({
            ...user,
            relevantRole_: role,
          });
        });
      });

      let assignedUsers_ = relevantRoleArr.reduce((agg, curr) => {
        let regionName = curr.relevantRole_.levelName.replaceAll("%20", " ");
        let leaderFound = relevantRoleArr.find((usr) => {
          return (
            usr.relevantRole_.officeID.includes("1") &&
            usr.relevantRole_.levelName === regionName
          );
        });
        let leader = leaderFound
          ? `${leaderFound.firstName} ${leaderFound.lastName}`
          : "---";
        agg[regionName] = agg[regionName]
          ? {
              leader,
              members: agg[regionName]
                ? [...agg[regionName].members, curr]
                : [curr],
            }
          : {
              leader,
              members: [curr],
            };
        return agg;
      }, {});
      setAssignedMembers(assignedUsers_);
    }
  }, [region, district, zone, branch]);

  useEffect(() => {
    let returnTo = location.search && parseQueryParams(location.search).values;

    if (returnTo) {
      let arr = returnTo.split("+");
      setRegion(arr[0]);
      setDistrict(arr[1]);
      setZone(arr[2]);
    }
  }, []);

  const parseQueryParams = (query) => {
    const queryArray = query.split("?")[1].split("&");
    let queryParams = {};
    for (let i = 0; i < queryArray.length; i++) {
      const [key, val] = queryArray[i].split("=");
      queryParams[key] = val ? val : true;
    }
    return queryParams;
  };

  return (
    <div style={{ overflow: "auto", width: "100%" }}>
      <div className="selects">
        <div className="select">
          {(level === "district" ||
            level === "zonal" ||
            level === "branch") && (
            <>
              <p style={{ color: "#666666", marginBottom: 5 }}>Region</p>
              <Select
                style={{ width: 150, marginRight: 10 }}
                onChange={(e) => {
                  setRegion(e);
                }}
                value={region}
                defaultValue={
                  level_ !== "region"
                    ? myCurrentRole.region
                    : currentRole.levelName
                }
              >
                {churches.regions.map((region) => (
                  <Option
                    value={region.value}
                    disabled={
                      currentRole.level_ !== "weca" &&
                      myCurrentRole.region &&
                      myCurrentRole.region.name !== region.value
                    }
                  >
                    {region.name}
                  </Option>
                ))}
              </Select>
            </>
          )}
        </div>
        <div className="select">
          {(level === "zonal" || level === "branch") && (
            <>
              <p style={{ color: "#666666", marginBottom: 5 }}>District</p>
              <Select
                style={{ width: 150, marginRight: 10 }}
                onChange={(e) => setDistrict(e)}
                value={district}
              >
                {churches.districts
                  .filter((d) => d.parent.name === myCurrentRole.region)
                  .map((district) => (
                    <Option
                      value={district.value}
                      disabled={
                        myCurrentRole.district &&
                        myCurrentRole.district !== district.value
                      }
                    >
                      {district.name}
                    </Option>
                  ))}
              </Select>
            </>
          )}
        </div>

        <div className="select">
          {level === "branch" && (
            <>
              <p style={{ color: "#666666", marginBottom: 5 }}>Zone</p>
              <Select
                style={{ width: 150, marginRight: 10 }}
                onChange={(e) => setZone(e)}
                value={zone}
              >
                {churches.zones
                  .filter((d) => d.parent.name === district)
                  .map((zone) => (
                    <Option
                      value={zone.value}
                      disabled={
                        myCurrentRole.zone && myCurrentRole.zone !== zone.value
                      }
                    >
                      {zone.name}
                    </Option>
                  ))}
              </Select>
            </>
          )}
        </div>
      </div>

      <div
        className="shadowed table-wrap"
        style={{ paddingTop: 20, marginTop: 30 }}
      >
        <div className="headings">
          {headingsToUse.map((heading) => (
            <div
              className="heading"
              style={{ width: `${100 / headingsToUse.length}%` }}
            >
              {heading}
            </div>
          ))}
        </div>
        {dataToUse.map((row) => (
          <div className="list-items">
            {headingsToUse.map((heading) => {
              return heading === "Name" ? (
                <div
                  className="list-item"
                  style={{ width: `${100 / headingsToUse.length}%` }}
                >
                  {row.name}
                </div>
              ) : heading === "Actions" ? (
                <div
                  className="list-item"
                  style={{ width: `${100 / headingsToUse.length}%` }}
                >
                  <Link
                    to={`/dashboard/assign-structure?level=${
                      level === "regional"
                        ? "region"
                        : level === "zonal"
                        ? "zone"
                        : level
                    }&name=${
                      row.name
                    }&returnTo=${region}+${district}+${zone}&key=${currentKey}`}
                  >
                    <Button
                      disabled={
                        currentRole.level_ === "region" && level_ === "region"
                          ? row.name !==
                            currentRole.levelName.replaceAll("%20", " ")
                          : currentRole.level_ === "district" &&
                            level_ === "district"
                          ? row.name !==
                            currentRole.levelName.replaceAll("%20", " ")
                          : currentRole.level_ === "zone" && level_ === "zone"
                          ? row.name !==
                            currentRole.levelName.replaceAll("%20", " ")
                          : currentRole.level_ === "branch" &&
                            level_ === "branch"
                          ? row.name !==
                            currentRole.levelName.replaceAll("%20", " ")
                          : false
                      }
                      type="primary"
                    >
                      manage users
                    </Button>
                  </Link>
                </div>
              ) : heading.includes("No of") ? (
                <div
                  className="list-item"
                  style={{ width: `${100 / headingsToUse.length}%` }}
                >
                  {row.childCount}
                </div>
              ) : heading === "Youth head" ? (
                <div
                  className="list-item"
                  style={{ width: `${100 / headingsToUse.length}%` }}
                >
                  {assignedMembers[row.name]
                    ? assignedMembers[row.name].leader
                    : "---"}
                </div>
              ) : (
                <div
                  className="list-item"
                  style={{ width: `${100 / headingsToUse.length}%` }}
                >
                  {`${
                    assignedMembers[row.name]
                      ? assignedMembers[row.name].members.length
                      : 0
                  }/${roles[level_].length}`}
                </div>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
}
