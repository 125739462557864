import axios from "axios";

// const baseUrl = "http://91.235.129.103:3800/";
// const baseUrl_ = "https://afmpass.name.ng/afmmembers/api/v1/";
// const churchesUrl = `${baseUrl}churchData`;
// const officerLevelsUrl = `${baseUrl_}config/resources/ydd-officer-level`;
let token = localStorage.getItem("afm_token");

// const fetchChurches = () => {
//   return axios({
//     method: "get",
//     url: churchesUrl,
//   });
// };

const fetchOfficerLevels = () => {
  return axios({
    method: "get",
    url: `https://afmpass.name.ng/afmconfig/api/v1/config/resources/ydd-officer-level`,
  });
};

const fetchAfcfOfficerLevels = () => {
  return axios({
    method: "get",
    url: `https://afmpass.name.ng/afmconfig/api/v1/config/resources/afcf-officer-level`,
  });
};
const fetchSchools = () => {
  return axios({
    method: "get",
    url: `https://afmpass.name.ng/afmcampuses/api/v1/campuses/schools`,
  });
};

// const fetchChurches = () => {
//   return axios({
//     method: "get",
//     url: `https://afmpass.name.ng/afmchurches/api/v1/churchData`,
//   });
// };

const fetchChurches_ = () => {
  return axios({
    method: "get",
    url: `https://afmpass.name.ng/afmchurches/api/v1/churches/all`,
  });
};

const userLogin = (data) => {
  return axios({
    method: "post",
    // url: `http://91.235.129.103:7100/user/login`,
    url: `https://afmpass.name.ng/afmmembers/api/v1/user/login`,
    data,
    headers: {
      clientId: "A6w0Xu6",
      // authId: "JZNJY9YQSwWaoQ5gd71iuA==",
    },
  });
};

const updateUser = (data, id) => {
  return axios({
    method: "put",
    url: `https://afmpass.name.ng/afmmembers/api/v1/user/${id}`,
    data,
    headers: {
      clientId: "A6w0Xu6",
      authId: "JZNJY9YQSwWaoQ5gd71iuA==",
      Authorization: `Bearer ${token}`,
    },
  });
};

const fetchUsers = () => {
  return axios({
    method: "get",
    url: `https://afmpass.name.ng/afmmembers/api/v1/user/`,
    headers: {
      clientId: "A6w0Xu6",
      authId: "JZNJY9YQSwWaoQ5gd71iuA==",
      Authorization: `Bearer ${token}`,
    },
  });
};

const updateOverseerInfo = (data) => {
  return axios({
    method: "put",
    url: `https://afmpass.name.ng/afmchurches/api/v1/churches/toplevels/`,
    data,
    headers: {
      clientId: "A6w0Xu6",
      authId: "JZNJY9YQSwWaoQ5gd71iuA==",
      Authorization: `Bearer ${token}`,
    },
  });
};

const updatePastorInfo = (data) => {
  return axios({
    method: "put",
    url: `https://afmpass.name.ng/afmchurches/api/v1/churches/branches/`,
    data,
    headers: {
      clientId: "A6w0Xu6",
      authId: "JZNJY9YQSwWaoQ5gd71iuA==",
      Authorization: `Bearer ${token}`,
    },
  });
};

const sendMailToUser = (data) => {
  return axios({
    method: "post",
    url: `https://afmpass.name.ng/afmmembers/api/v1/user/emailRole`,
    data,
    headers: {
      clientId: "A6w0Xu6",
      // authId: "JZNJY9YQSwWaoQ5gd71iuA==",
      Authorization: `Bearer ${token}`,
    },
  });
};

export default {
  // fetchChurches,
  fetchOfficerLevels,
  fetchAfcfOfficerLevels,
  fetchChurches_,
  fetchUsers,
  userLogin,
  updateUser,
  updateOverseerInfo,
  updatePastorInfo,
  sendMailToUser,
  fetchSchools,
};
