import React, { useEffect, useState } from "react";

import { Col, Row, Input, Select, Button, Form, Table, notification } from "antd";
import { EditOutlined, PlusCircleFilled } from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";
import { Link, useLocation } from "react-router-dom";
import apiServices from "../../../../../Services/apiServices";

const { Option } = Select;

export default function SchoolsTable({ level, roles, schools, getSchools }) {
  let location = useLocation();
  const [dataSource, setDataSource] = useState([]);
  const [afcfOfficers, setAfceOffices] = useState([]);
  const [state, setState] = useState("");
  const [loading, setLoading] = useState(false);
  let members_ = localStorage.getItem("afm_members");
  const [columns, setColumns] = useState(
    level === "school"
      ? [
          {
            title: "School",
            dataIndex: "school",
            key: "school",
          },
          {
            title: "Coordinator",
            dataIndex: "coordinator",
            key: "coordinator",
          },
          {
            title: "General Secretary",
            dataIndex: "secretary",
            key: "secretary",
          },
        ]
      : [
          {
            title: "District",
            dataIndex: "district",
            key: "district",
          },
          {
            title: "State President",
            dataIndex: "president",
            key: "president",
            render: (e, f) => {
              return (
                <div>
                  <p style={{ textTransform: "capitalize" }}>
                    {e?.firstName} {e?.lastName}
                  </p>
                  <p>
                    {e?.email} - {e?.phoneNumber}
                  </p>
                </div>
              );
            },
          },
          {
            title: "State Secretary",
            dataIndex: "secretary",
            key: "secretary",
            render: (e, f) => {
              return (
                <div>
                  <p style={{ textTransform: "capitalize" }}>
                    {e?.firstName} {e?.lastName}
                  </p>
                  <p>
                    {e?.email} - {e?.phoneNumber}
                  </p>
                </div>
              );
            },
          },
          {
            title: "Action",
            dataIndex: "action",
            render: (e, f) => (
              <Button
                onClick={() => {
                  setOpen(true);
                  setState(f.name);
                }}
              >
                Assign officers
              </Button>
            ),
          },
        ]
  );
  const [open, setOpen] = useState(false);
  const [members, setMembers] = useState(members_ ? JSON.parse(members_) : []);
  const [districts, setDistricts] = useState([]);
  const currentLevelRoles = roles[level] || [];
  useEffect(() => {
    let isAfcfOficer = members
      .filter((m) => {
        let role = m.roleId && m.roleId.includes("[") && checkIsOffcer(m.roleId);
        return role;
      })
      .map((user) => {
        return {
          ...user,
          roleData: JSON.parse(user.roleId).filter((r) => checkIsOffcer(r.officeID)),
        };
      });

    setAfceOffices(isAfcfOficer);
  }, [members]);

  useEffect(() => {
    fetchallMembers();
    let chrch = localStorage.getItem("afm_churches");
    if (chrch) {
      let initial = JSON.parse(chrch);

      let districts_ = initial.districts.map((district) => {
        return {
          ...district,
          childCount: initial.zones.filter((d) => d.parent.name === district.name).length,
        };
      });

      setDistricts(districts_);
    }
  }, []);

  useEffect(() => {
    let data;
    if (level === "school") {
      data = schools.map((school) => {
        return {
          ...school,
          school: school.name,
          coordinator: school.coordinator,
          secretary: school.secretary,
        };
      });
    } else {
      data = districts
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((dist) => {
          let presidentID = currentLevelRoles.find((role) => role.office.toLowerCase().includes("president")).id;
          let secretaryID = currentLevelRoles.find((role) => role.office.toLowerCase().includes("secretary")).id;
          let whoIsHere = afcfOfficers
            .filter((o) => {
              let isThisLevel = o.roleData.map((r) => r.level_).includes(level);
              let isThisState = o.roleData.map((r) => r.levelName).includes(dist.name);

              return isThisLevel && isThisState;
            })
            .map((usr) => {
              return {
                ...usr,
                isPresident: usr.roleData.map((r) => r.officeID).includes(presidentID),
                isSecretary: usr.roleData.map((r) => r.officeID).includes(secretaryID),
              };
            })
            .reduce((data, curr) => {
              if (curr.isPresident) data.president = curr;
              if (curr.isSecretary) data.secretary = curr;
              return data;
            }, {});

          return {
            ...dist,
            district: dist.name,
            president: whoIsHere.president,
            secretary: whoIsHere.secretary,
          };
        });
    }
    setDataSource(data);
  }, [districts, schools, afcfOfficers, level]);

  const checkIsOffcer = (id) => {
    let checks = currentLevelRoles.map((r) => {
      return id.includes(r.id);
    });
    return checks.includes(true);
  };

  const fetchallMembers = () => {
    apiServices
      .fetchUsers()
      .then((res) => {
        localStorage.setItem("afm_members", JSON.stringify(res.data));
        setMembers(res.data);
      })
      .catch((err) => {
        // console.log({ err });
      });
  };

  const onFinish = (values) => {
    let user = members.find((user) => user.email === values.official);
    let officeID = values.office;
    let oldOfficer = afcfOfficers.find((usr) => {
      return usr.roleId?.includes(officeID) && usr.roleId.includes(state);
    });
    let selectedOffice = currentLevelRoles.find((r) => r.id == officeID).office;
    setLoading(true);
    if (oldOfficer) {
      removeRole(oldOfficer, officeID);
    }

    assignMe(user, officeID);
    let emailData = {
      email: user.email,
      name: user.firstName,
      role: selectedOffice,
      church: state,
      level: level,
      frontend_url: window.location.origin,
    };

    sendMail(emailData);
  };

  const sendMail = (data) => {
    apiServices
      .sendMailToUser(data)
      .then((res) => {})
      .catch((err) => {
        // console.log(err);
      })
      .finally(() => setLoading(false));
  };

  const assignMe = (user, officeID) => {
    let newRole = { officeID: officeID, level_: level, levelName: state };
    let roleInfo = user.roleId && user.roleId.includes("[") ? JSON.parse(user.roleId) : [];
    let userRoles = [newRole, ...roleInfo];
    let data = {
      ...user,
      roleId: userRoles.length ? JSON.stringify(userRoles) : "",
    };

    if (
      roleInfo &&
      roleInfo.find((role) => role.officeID === officeID && role.levelName.replaceAll("%20", " ") === state)
    ) {
      notification.info({
        description: "This user is already assigned to this role",
      });
      setLoading(false);
      return;
    }

    apiServices
      .updateUser(data, user.id)
      .then((res) => {
        fetchallMembers();
        getSchools();
        notification.success({ description: "User role updated" });
      })
      .catch((err) => {
        // console.log(err);
        notification.error({
          description: "An error occured, please try again",
        });
      })
      .finally(() => {
        setLoading(false);
        setOpen(false);
      });
  };

  const removeRole = (user, officeID) => {
    if (user) {
      let roleInfo = user.roleId ? JSON.parse(user.roleId) : [];
      let userRoles = roleInfo.filter((role) => role.officeID !== officeID);
      let data = {
        ...user,
        roleId: userRoles.length ? JSON.stringify(userRoles) : "",
      };
      apiServices
        .updateUser(data, user.id)
        .then((res) => {
          fetchallMembers();
        })
        .catch((err) => {
          // console.log(err);
        });
      // .finally(() => setLoading(false));
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div style={{ overflow: "auto", width: "100%" }}>
      <div className="shadowed table-wrap" style={{ paddingTop: 20, marginTop: 30 }}>
        <Table columns={columns} dataSource={dataSource} />
      </div>

      <Modal visible={open} title="Assign officer" footer={null} onCancel={() => setOpen(false)}>
        <Form
          name="basic"
          layout="vertical"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item label="Office" name="office" rules={[{ required: true, message: "Please select a office!" }]}>
            <Select style={{ width: 400 }}>
              {currentLevelRoles.map((role, i) => (
                <Option key={role.id}>{role.office}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Official" name="official" rules={[{ required: true, message: "Please select a officer!" }]}>
            <Select
              showSearch
              style={{ width: 400 }}
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              filterSort={(optionA, optionB) =>
                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
              }
            >
              {members.map((person, i) => (
                <Option
                  key={person.email}
                  value={person.email}
                >{`${person.firstName} ${person.lastName} - (${person.username})`}</Option>
              ))}
            </Select>
          </Form.Item>
          <div style={{ textAlign: "right" }}>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button loading={loading} type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </div>
  );
}
