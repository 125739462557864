import React, { useEffect, useState } from "react";

import { Button, Select, Input, Switch, Divider, notification, Tag } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import { Router, useLocation, useRouteMatch, withRouter } from "react-router";
import Modal from "antd/lib/modal/Modal";
import apiServices from "../../../../../Services/apiServices";
import { ArrowLeftOutlined } from "@ant-design/icons";

const { Option } = Select;
const { TextArea } = Input;

export default withRouter(function RegionTable({ roles, level, history }) {
  let location = useLocation();

  let chrch = localStorage.getItem("afm_churches");
  let members_ = localStorage.getItem("afm_members");
  let currentRole = localStorage.getItem("current_role");

  let myRole = currentRole
    ? JSON.parse(currentRole)
        .officeID.replaceAll("1", "")
        .replaceAll("2", "")
        .replaceAll("3", "")
        .replaceAll("4", "")
        .replaceAll("5", "")
        .replaceAll("6", "")
        .replaceAll("7", "")
        .replaceAll("8", "")
        .replaceAll("9", "")
    : null;

  const [defaultTabKey, setDefaultTabKey] = useState(
    myRole.includes("w")
      ? "0"
      : myRole.includes("r")
      ? "1"
      : myRole.includes("d")
      ? "2"
      : myRole.includes("z")
      ? "3"
      : "4"
  );

  const [roles_, setroles_] = useState(roles);
  const [level_, setLevel_] = useState(level ? level : "region");
  const [levelName, setLevelName] = useState("");
  const [showAdd, setShowAdd] = useState(false);
  const [members, setMembers] = useState(members_ ? JSON.parse(members_) : []);
  const [assignedMembers, setAssignedMembers] = useState([]);
  const [selectedOffice, setSelectedOffice] = useState("");
  const [oldOfficer, setOldOfficer] = useState(null);
  const [updatedMmbers, setUpdatedMmbers] = useState(0);
  const [fullLevelData, setFullLevelData] = useState(roles_[level_]);
  const [churches, setChurches] = useState({
    branches: [],
    districts: [],
    regions: [],
    zones: [],
  });
  const [error, setError] = useState(false);
  const chrchBranches = churches.branches.reduce((agg, curr) => {
    agg[curr.churchId] = curr;
    return agg;
  }, {});

  const [selectedUser, setSelectedUser] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    chrch && setChurches(JSON.parse(chrch));
    let levelName =
      location.search && parseQueryParams(location.search).name
        ? parseQueryParams(location.search).name.replaceAll("%20", " ")
        : "weca";
    let assignedUsers = JSON.parse(members_)
      .map((user) => {
        return {
          ...user,
          roleInfo: user.roleId && user.roleId.includes("[") ? JSON.parse(user.roleId).filter((r) => r) : null,
        };
      })
      .filter((u) => u.roleInfo);
    let levelRolesWithMembers = {
      ...roles_,
      [level_]:
        roles_[level_]?.map((item) => {
          let levelOfficer = assignedUsers.find((u) => {
            return u.roleInfo.find((roleData) => {
              return (
                roleData.level_ === level_ &&
                roleData.officeID === item.id &&
                roleData.levelName.replaceAll("%20", " ") === levelName
              );
            });
          });
          return {
            ...item,
            levelOfficer,
          };
        }) || [],
    };
    setFullLevelData(levelRolesWithMembers[level_]);
    setAssignedMembers(assignedUsers);
  }, [members, level_]);

  useEffect(() => {
    location.search && parseQueryParams(location.search);
  }, [location.search]);

  useEffect(() => {
    setroles_(roles_);
  }, [roles_]);

  const pickUser = (username) => {
    let user = members.find((member) => {
      return member.username === username;
    });
    // console.log(user);
    let userBranch = churches.branches.find((b) => b.churchId === user.churchId);
    let branch = userBranch ? userBranch.name : "---";
    let data = {
      ...user,
      branch,
      theirRoles: user.roleId.includes("[") ? JSON.parse(user.roleId) : [],
    };
    setSelectedUser(data);
  };

  const removeRole = (user) => {
    // console.log({ oldUser: user });
    if (user) {
      let officeID = roles_[level_].find((l) => l.office === selectedOffice).id;
      let userRoles = user.roleInfo.filter((role) => role.officeID !== officeID);
      let data = {
        ...user,
        roleId: userRoles.length ? JSON.stringify(userRoles) : "",
      };
      apiServices
        .updateUser(data, user.id)
        .then((res) => {
          fetchallMembers();
          // console.log("role reversed");
        })
        .catch((err) => {
          // console.log(err);
        })
        .finally(() => setLoading(false));
    }
  };

  const assignMe = () => {
    let officeID = roles_[level_].find((l) => l.office === selectedOffice).id;
    if (
      selectedUser.roleInfo &&
      selectedUser.roleInfo.find(
        (role) => role.officeID === officeID && role.levelName.replaceAll("%20", " ") === levelName
      )
    ) {
      // console.log("already assigned to here");
      return;
    } else {
      if (oldOfficer) {
        removeRole(oldOfficer);
      }
      let newRoldID_ = [
        {
          officeID,
          level_,
          levelName: levelName || "weca",
        },
      ];
      let fomerRoles =
        selectedUser.roleId && selectedUser.roleId.includes("[") ? JSON.parse(selectedUser.roleId) : null;
      let oldRoleID = fomerRoles ? fomerRoles : [];

      let newRoldID = oldRoleID ? [...newRoldID_, ...oldRoleID] : newRoldID_;
      let data = {
        ...selectedUser,
        // userRoles: JSON.stringify(newRoldID),
        roleId: JSON.stringify(newRoldID),
      };
      delete data.password;

      let emailData = {
        email: selectedUser.email,
        name: selectedUser.firstName,
        role: selectedOffice,
        church: levelName,
        level: level_,
        frontend_url: window.location.origin,
      };

      sendMail(emailData);

      setLoading(true);
      apiServices
        .updateUser(data, selectedUser.id)
        .then((res) => {
          fetchallMembers();
          setShowAdd(false);
          openNotificationWithIcon("success", "User successfully assigned");
        })
        .catch((err) => {
          // console.log(err);
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    fetchallMembers();
  }, []);

  const openNotificationWithIcon = (type, description) => {
    notification[type]({
      message: type.toUpperCase(),
      description,
    });
  };

  const sendMail = (data) => {
    apiServices
      .sendMailToUser(data)
      .then((res) => {})
      .catch((err) => {
        // console.log(err);
      })
      .finally(() => setLoading(false));
  };

  const fetchallMembers = () => {
    apiServices
      .fetchUsers()
      .then((res) => {
        localStorage.setItem("afm_members", JSON.stringify(res.data));
        setMembers(res.data);
      })
      .catch((err) => {
        // console.log({ err });
      });
  };

  const parseQueryParams = (query) => {
    const queryArray = query.split("?")[1].split("&");
    let queryParams = {};
    for (let i = 0; i < queryArray.length; i++) {
      const [key, val] = queryArray[i].split("=");
      queryParams[key] = val ? val : true;
    }
    queryParams.level && setLevel_(queryParams.level);
    setLevelName(queryParams.name && queryParams.name.replaceAll("%20", " "));
    return queryParams;
  };

  return (
    <div style={{ overflow: "auto", width: "100%" }}>
      <p
        style={{ cursor: "pointer" }}
        onClick={() => {
          history.push(
            `/dashboard/structure?key=${parseQueryParams(location.search).key || defaultTabKey}&values=${
              parseQueryParams(location.search).returnTo
            }`
          );
        }}
      >
        {levelName && (
          <span>
            <ArrowLeftOutlined style={{ fontSize: 14, marginRight: 20, cursor: "pointer" }} /> Back
          </span>
        )}
      </p>
      {levelName && (
        <p
          style={{
            fontSize: 30,
            fontWeight: "600",
            display: "flex",
            alignItems: "center",
          }}
        >
          {levelName.replaceAll("%20", " ")} {level_ === "zonal" ? "zone" : level_ === "regional" ? "region" : level_}
        </p>
      )}
      <div className="shadowed table-wrap" style={{ marginTop: 30 }}>
        {level_ &&
          roles_ &&
          roles_[level_] &&
          fullLevelData.map((level) => {
            return (
              <div className="assigned-user-wrap">
                <div className="role-title">{level.office}</div>
                {level.levelOfficer ? (
                  <div className="assigned-user">
                    {/* <Avatar
                      size='large'
                      src='https://randomuser.me/api/portraits/men/16.jpg'
                    /> */}
                    <div style={{ marginLeft: 20 }}>
                      <p
                        style={{
                          marginBottom: 2,
                          fontWeight: 600,
                          fontSize: 16,
                        }}
                      >
                        {level.levelOfficer ? `${level.levelOfficer.firstName} ${level.levelOfficer.lastName}` : "---"}
                      </p>
                      <p
                        style={{
                          marginBottom: 2,
                          color: "#666666",
                          fontSize: 14,
                        }}
                      >
                        {level.levelOfficer ? `${level.levelOfficer.phoneNumber}` : "---"}
                      </p>
                      <p
                        style={{
                          marginBottom: 2,
                          color: "#666666",
                          fontSize: 14,
                        }}
                      >
                        {level.levelOfficer ? `${level.levelOfficer.email}` : "---"}
                      </p>
                      <p
                        style={{
                          marginBottom: 2,
                          color: "#666666",
                          fontSize: 14,
                        }}
                      >
                        {level.levelOfficer
                          ? chrchBranches[level.levelOfficer.churchId] &&
                            chrchBranches[level.levelOfficer.churchId].name
                          : "---"}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="assigned-user">---</div>
                )}
                <div className="actions">
                  <Button
                    onClick={() => {
                      setOldOfficer(level.levelOfficer);
                      setShowAdd(true);
                      setSelectedUser({});
                      // console.log(level);
                      setSelectedOffice(level.office);
                    }}
                  >
                    {level.levelOfficer ? `Re-assign` : `Assign`} user
                  </Button>
                </div>
              </div>
            );
          })}
      </div>

      <Modal onCancel={() => setShowAdd(false)} visible={showAdd} title="Assign role" footer={null}>
        <p style={{ fontSize: 14, marginBottom: 5, color: "#eee" }}>Select user</p>
        <Select
          showSearch
          style={{ width: 400 }}
          placeholder="Search to Select"
          optionFilterProp="children"
          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          filterSort={(optionA, optionB) =>
            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
          }
          onChange={(e) => pickUser(e)}
        >
          {members.map((person, i) => (
            <Option key={person.username}>{`${person.firstName} ${person.lastName} - (${person.username})`}</Option>
          ))}
        </Select>

        {selectedUser && selectedUser.firstName && (
          <>
            <Divider />
            <p style={{ fontSize: 14, marginBottom: 4 }}>
              <b>First Name:</b> {selectedUser.firstName}
            </p>
            <p style={{ fontSize: 14, marginBottom: 4 }}>
              <b>Last Name:</b> {selectedUser.lastName}
            </p>
            <p style={{ fontSize: 14, marginBottom: 4 }}>
              <b>Username:</b> {selectedUser.username}
            </p>
            <p style={{ fontSize: 14, marginBottom: 4 }}>
              <b>Branch:</b> {selectedUser.branch}
            </p>
            {selectedUser.theirRoles.length && (
              <p>
                <b>Current office(s): </b>
                {selectedUser.theirRoles.map((role) => (
                  <Tag color="processing">
                    {role.levelName.replaceAll("%20", " ")}{" "}
                    {roles[role.level_].find((r) => r.id === role.officeID).office}
                  </Tag>
                ))}
              </p>
            )}
          </>
        )}

        <Divider />

        <Button type="primary" disabled={selectedUser && !selectedUser.firstName} onClick={assignMe} loading={loading}>
          {`Assign user to ${levelName || ""} ${selectedOffice}`}
        </Button>
      </Modal>
    </div>
  );
});
