import React, { useEffect } from "react";
import "./Structure.scss";
import apiServices from "../../../../Services/apiServices";
import { useState } from "react";

import empty from "../../../../assets/empty.svg";
import { Col, Row, Input, Select, Button, Switch } from "antd";
import { EditOutlined, PlusCircleFilled } from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";

const { Option } = Select;
const { TextArea } = Input;

export default function Structure() {
  const officeLevels = [
    { text: "WECA", value: 0, key: "WECA" },
    { text: "Regional", value: 1, key: "Regional" },
    { text: "District", value: 2, key: "District" },
    { text: "Zonal", value: 3, key: "Zonal" },
    { text: "Branch", value: 4, key: "Branch" },
  ];

  const selectedRoles = () => {
    let level = officeLevel;
    let data = [];
    if (level === 4) {
      data = levels.branch;
    } else if (level === 3) {
      data = levels.zone;
    } else if (level === 2) {
      data = levels.district;
    } else if (level === 1) {
      data = levels.region;
    } else {
      data = levels.weca;
    }
    return data.map((office) => {
      return {
        text: office.office,
        value: office.id,
        key: office.id,
      };
    });
  };

  const [churchData, setchurchData] = useState({});
  const [districts, setDistricts] = useState([]);
  const [zones, setZones] = useState([]);
  const [branches, setBranches] = useState([]);
  const [region, setRegion] = useState("");
  const [district, setDistrict] = useState("");
  const [zone, setZone] = useState("");
  const [branch, setBranch] = useState("");
  const [role, setRole] = useState("");
  const [officeLevel, setOfficeLevel] = useState(0);
  const [searching, setSearching] = useState(false);
  const [searched, setSearched] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [levels, setLevels] = useState({});
  const [pickedLevel, setPickedLevel] = useState("weca");
  const [newLevel, setnewLevel] = useState({
    id: "",
    office: "",
  });

  const regions = churchData.region
    ? churchData.region.map((text) => {
        return {
          text,
          value: text,
          key: text,
        };
      })
    : [];

  useEffect(() => {
    getChurchData();
    getLevels();
    let offices = localStorage.getItem("afm_officeLevels");
    offices && setLevels(JSON.parse(offices));
  }, []);

  const searchUser = () => {
    setSearching(true);
    setTimeout(() => {
      setSearching(false);
      setSearched(true);
    }, 3000);
  };

  const getLevels = () => {
    apiServices
      .fetchOfficerLevels()
      .then((res) => {
        setLevels(res.data.data);
        localStorage.setItem("afm_officeLevels", JSON.stringify(res.data.data));
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  const getChurchData = () => {
    apiServices
      .fetchChurches_()
      .then((res) => {
        setchurchData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const pickValue = (type, val) => {
    switch (type) {
      case "region":
        let districts_ = churchData.district
          .find((d) => d.region === val)
          .district.map((text) => {
            return {
              text,
              value: text,
              key: text,
            };
          });
        setRegion(val);
        setDistricts(districts_);
        break;
      case "district":
        let zones = churchData.zone
          .find((d) => d.district === val)
          .zone.map((text) => {
            return {
              text,
              value: text,
              key: text,
            };
          });
        setZones(zones);
        setDistrict(val);
        break;
      case "zone":
        let branches = churchData.branch
          .find((d) => d.zone === val)
          .branch.map((text) => {
            return {
              text,
              value: text,
              key: text,
            };
          });
        setBranches(branches);
        setZone(val);
        break;
      case "branch":
        setBranch(val);
        break;
      case "role":
        setRole(val);
        break;
      case "officeLevel":
        if (val === 0) {
          setRegion("");
          setDistrict("");
          setZone("");
          setBranch("");
        } else if (val === 1) {
          setDistrict("");
          setZone("");
          setBranch("");
        } else if (val === 2) {
          setZone("");
          setBranch("");
        } else if (val === 3) {
          setBranch("");
        }
        setOfficeLevel(val);
        break;
      default:
        break;
    }
  };

  return (
    <div className="structure">
      <p className="page-title">Manage Structure</p>
      <Row gutter={20}>
        {Object.keys(levels).map((level) => {
          return (
            <Col md={12} sm={24} xs={24}>
              <div className="shadowed roles">
                <p className="top">
                  <span>{level}</span>{" "}
                  {/* <PlusCircleFilled
                    onClick={() => {
                      setShowAdd(true);
                      setPickedLevel(level);
                    }}
                    style={{ cursor: "pointer" }}
                  />{" "} */}
                </p>
                {levels[level].map((role) => {
                  return (
                    <div className="role">
                      <span>{role.office}</span>
                      {/* <EditOutlined
                        onClick={() => {
                          setShowAdd(true);
                          setPickedLevel(level);
                          setnewLevel(role);
                        }}
                      /> */}
                    </div>
                  );
                })}
              </div>
            </Col>
          );
        })}
      </Row>

      <Modal
        onCancel={() => {
          setShowAdd(false);
          setnewLevel({ id: "", office: "" });
        }}
        visible={showAdd}
        title="Add role"
      >
        <p style={{ fontSize: 14, marginBottom: 5 }}>Church level</p>
        <Select size="large" value={pickedLevel} style={{ width: "100%", marginBottom: 15 }} disabled>
          {officeLevels.map((office) => (
            <Option value={office.value}>{office.text}</Option>
          ))}
        </Select>

        <p style={{ fontSize: 14, marginBottom: 5 }}>Role name</p>
        <Input
          size="large"
          style={{ width: "100%", marginBottom: 15 }}
          defaultValue={newLevel.office}
          value={newLevel.office}
          onChange={(e) => {
            setnewLevel({
              ...newLevel,
              office: e.target.value,
            });
          }}
        />

        <p style={{ fontSize: 14, marginBottom: 5 }}>Description</p>
        <TextArea rows={4} style={{ marginBottom: 15 }} defaultValue={newLevel.desc} />

        <p style={{ fontSize: 14, marginBottom: 5 }}>Status</p>
        <Switch defaultChecked={newLevel.status} />
      </Modal>
    </div>
  );
}
