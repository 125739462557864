import React, { useEffect, useState } from "react";
import "./Structure.scss";
import { Tabs } from "antd";
import LevelTable from "./LevelTable/LevelTable";
import RegionTable from "./LevelTable/RegionTable";
import apiServices from "../../../../Services/apiServices";
import { useLocation } from "react-router";

const { TabPane } = Tabs;

export default function ManageStructure({ officeLevels, levels, setUserSelections }) {
  let location = useLocation();

  const user = JSON.parse(localStorage.getItem("user"));
  let currentRole = localStorage.getItem("current_role");
  let myRole = currentRole
    ? JSON.parse(currentRole)
        .officeID.replaceAll("1", "")
        .replaceAll("2", "")
        .replaceAll("3", "")
        .replaceAll("4", "")
        .replaceAll("5", "")
        .replaceAll("6", "")
        .replaceAll("7", "")
        .replaceAll("8", "")
        .replaceAll("9", "")
    : null;

  const allowedRoles = {
    w: ["weca", "regional", "district", "zonal", "branch"],
    r: ["regional", "district", "zonal", "branch"],
    d: ["district", "zonal", "branch"],
    z: ["zonal", "branch"],
    b: ["branch"],
  };

  const [currentKey, setKey] = useState("0");

  useEffect(() => {
    if (location.search) {
      let key = parseQueryParams(location.search).key;
      setKey(key);
    }
  }, []);

  const parseQueryParams = (query) => {
    const queryArray = query.split("?")[1].split("&");
    let queryParams = {};
    for (let i = 0; i < queryArray.length; i++) {
      const [key, val] = queryArray[i].split("=");
      queryParams[key] = val ? val : true;
    }
    return queryParams;
  };

  return (
    <div className="structure">
      <Tabs
        onChange={(e) => {
          setKey(e.toString());
        }}
        defaultActiveKey={
          parseQueryParams(location.search) && parseQueryParams(location.search).key
            ? parseQueryParams(location.search).key
            : myRole.includes("w")
            ? "0"
            : myRole.includes("r")
            ? "1"
            : myRole.includes("d")
            ? "2"
            : myRole.includes("z")
            ? "3"
            : "4"
        }
      >
        {officeLevels.map((group, i) => (
          <TabPane tab={group.text.toUpperCase()} key={`${i}`} disabled={!allowedRoles[myRole]?.includes(group.text)}>
            {i === 0 ? (
              <RegionTable roles={levels} level={group.key.toLowerCase()} />
            ) : (
              <LevelTable currentKey={currentKey} roles={levels} level={group.key.toLowerCase()} />
            )}
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
}
