import { Button, Col, Form, Input, notification, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import apiServices from "../../../../Services/apiServices";
import "./Platforms.scss";

function Platforms(props) {
  const router = useParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [sum, setSum] = useState(0);
  const [churches, setChurches] = useState({
    branches: [],
    districts: [],
    regions: [],
    zones: [],
  });
  const user = JSON.parse(localStorage.getItem("user"));
  let currentRole = localStorage.getItem("current_role");
  let chrch = localStorage.getItem("afm_churches");
  let myRole = currentRole
    ? JSON.parse(currentRole)
        .officeID.replaceAll("1", "")
        .replaceAll("2", "")
        .replaceAll("3", "")
        .replaceAll("4", "")
        .replaceAll("5", "")
        .replaceAll("6", "")
        .replaceAll("7", "")
        .replaceAll("8", "")
        .replaceAll("9", "")
    : null;

  useEffect(() => {
    chrch && setChurches(JSON.parse(chrch));
  }, []);

  const calcValues = () => {
    let values = form.getFieldsValue(true);
    let total = 0;
    if (values) {
      total = Object.values(values).reduce((total, curr) => (total += parseInt(curr) || 0), 0);
      setSum(total);
    }
  };

  const submitForm = () => {
    let data = {
      ...form.getFieldsValue(true),
      total: sum,
    };
    if (!getRoleInfo()) {
      notification.info({
        message: "Only branch youth leaders can update church info",
      });
      return;
    }
    let payload = { churchId: getRoleInfo()?.churchId };
    payload[router.type === "working" ? "careerInfo" : "maritalInfo"] = data;
    setLoading(true);
    apiServices
      .updateBranchInfo(payload)
      .then((res) =>
        notification.success({
          message: "Branch info updated successfully",
        })
      )
      .catch((err) => {
        console.log(err);
        notification.error({
          message: "Something went wrong. Kindly try again",
        });
      })
      .finally(() => setLoading(false));
  };

  const getRoleInfo = () => {
    let currentRole_ = JSON.parse(currentRole);
    let mine;
    if (currentRole_.level_ === "branch") {
      mine = churches.branches.find((b) => b.name === currentRole_.levelName.replaceAll("%20", " "));
    }
    return mine;
  };

  return (
    <div className="platforms">
      <h2>{router.type === "working" ? "Career Demographics" : "Marital Demographics"}</h2>
      <br />
      {router.type === "working" ? (
        <Form layout="vertical" form={form} onFieldsChange={calcValues} onFinish={submitForm}>
          <Row gutter={20}>
            <Col sm={24} md={12}>
              <Form.Item required rules={[{ required: true }]} label="Junior Secondary" name="juniors">
                <Input
                  suffix={`${
                    form.getFieldsValue(true).juniors ? ((form.getFieldsValue(true).juniors * 100) / sum).toFixed(2) : 0
                  }%`}
                  type="number"
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col sm={24} md={12}>
              <Form.Item required rules={[{ required: true }]} label="Senior Secondary" name="seniors">
                <Input
                  suffix={`${
                    form.getFieldsValue(true).seniors ? ((form.getFieldsValue(true).seniors * 100) / sum).toFixed(2) : 0
                  }%`}
                  type="number"
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col sm={24} md={12}>
              <Form.Item
                required
                rules={[{ required: true }]}
                label="Post Secondary/Uneducated Teenagers"
                name="postSecondary"
              >
                <Input
                  type="number"
                  size="large"
                  suffix={`${
                    form.getFieldsValue(true).postSecondary
                      ? ((form.getFieldsValue(true).postSecondary * 100) / sum).toFixed(2)
                      : 0
                  }%`}
                />
              </Form.Item>
            </Col>
            <Col sm={24} md={12}>
              <Form.Item required rules={[{ required: true }]} label="Undergraduates" name="undergraduates">
                <Input
                  type="number"
                  size="large"
                  suffix={`${
                    form.getFieldsValue(true).undergraduates
                      ? ((form.getFieldsValue(true).undergraduates * 100) / sum).toFixed(2)
                      : 0
                  }%`}
                />
              </Form.Item>
            </Col>
            <Col sm={24} md={12}>
              <Form.Item
                required
                rules={[{ required: true }]}
                label="Graduates/Post Graduates"
                name="freshGraduatesOrPostGraduates"
              >
                <Input
                  type="number"
                  size="large"
                  suffix={`${
                    form.getFieldsValue(true).freshGraduatesOrPostGraduates
                      ? ((form.getFieldsValue(true).freshGraduatesOrPostGraduates * 100) / sum).toFixed(2)
                      : 0
                  }%`}
                />
              </Form.Item>
            </Col>
            <Col sm={24} md={12}>
              <Form.Item required rules={[{ required: true }]} label="Corp Members" name="corpers">
                <Input
                  type="number"
                  size="large"
                  suffix={`${
                    form.getFieldsValue(true).corpers ? ((form.getFieldsValue(true).corpers * 100) / sum).toFixed(2) : 0
                  }%`}
                />
              </Form.Item>
            </Col>
            <Col sm={24} md={12}>
              <Form.Item required rules={[{ required: true }]} label="Working Class" name="employees">
                <Input
                  type="number"
                  size="large"
                  suffix={`${
                    form.getFieldsValue(true).employees
                      ? ((form.getFieldsValue(true).employees * 100) / sum).toFixed(2)
                      : 0
                  }%`}
                />
              </Form.Item>
            </Col>
            <Col sm={24} md={12}>
              <Form.Item required rules={[{ required: true }]} label="Entrepreneurs/Artisans" name="entrepreneurs">
                <Input
                  type="number"
                  size="large"
                  suffix={`${
                    form.getFieldsValue(true).entrepreneurs
                      ? ((form.getFieldsValue(true).entrepreneurs * 100) / sum).toFixed(2)
                      : 0
                  }%`}
                />
              </Form.Item>
            </Col>
            <Col sm={24} md={12}>
              <Form.Item required rules={[{ required: true }]} label="Retired" name="retired">
                <Input
                  type="number"
                  size="large"
                  suffix={`${
                    form.getFieldsValue(true).retired ? ((form.getFieldsValue(true).retired * 100) / sum).toFixed(2) : 0
                  }%`}
                />
              </Form.Item>
            </Col>
            <Col sm={24} md={12}>
              <Form.Item label="Total">
                <Input type="number" size="large" readOnly value={sum} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <Form layout="vertical" form={form} onFieldsChange={calcValues} onFinish={submitForm}>
          <Row gutter={20}>
            <Col sm={24} md={12}>
              <Form.Item required rules={[{ required: true }]} label="Singles" name="singles">
                <Input
                  suffix={`${
                    form.getFieldsValue(true).singles ? ((form.getFieldsValue(true).singles * 100) / sum).toFixed(2) : 0
                  }%`}
                  type="number"
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col sm={24} md={12}>
              <Form.Item required rules={[{ required: true }]} label="Married" name="married">
                <Input
                  suffix={`${
                    form.getFieldsValue(true).married ? ((form.getFieldsValue(true).married * 100) / sum).toFixed(2) : 0
                  }%`}
                  type="number"
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col sm={24} md={12}>
              <Form.Item required rules={[{ required: true }]} label="Widowed" name="widowed">
                <Input
                  type="number"
                  size="large"
                  suffix={`${
                    form.getFieldsValue(true).widowed ? ((form.getFieldsValue(true).widowed * 100) / sum).toFixed(2) : 0
                  }%`}
                />
              </Form.Item>
            </Col>
            <Col sm={24} md={12}>
              <Form.Item label="Total">
                <Input type="number" size="large" readOnly value={sum} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
}

export default Platforms;
