import { Dropdown, Layout, Menu, Result } from "antd";
import React, { useEffect, useState } from "react";
import { useRouteMatch, Switch, Route, withRouter, Link } from "react-router-dom";
import {
  DesktopOutlined,
  PieChartOutlined,
  FileOutlined,
  UsergroupAddOutlined,
  TeamOutlined,
  UserOutlined,
  ClusterOutlined,
  BarsOutlined,
  BuildOutlined,
  CoffeeOutlined,
  LogoutOutlined,
  MenuOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import Structure from "./Dashboard-pages/Structure/Structure";
import ManageStructure from "./Dashboard-pages/Structure/ManageStructure";
import RegionTable from "./Dashboard-pages/Structure/LevelTable/RegionTable";
import apiServices from "../../Services/apiServices";
import UpdateChurch from "./Dashboard-pages/updateChurch/UpdateChurch";
import DashboardHome from "./Dashboard-pages/home/Home";
import Platforms from "./Dashboard-pages/platforms/Platforms";
import AfcfStructure from "./Dashboard-pages/Structure/AfcfStructure";
import Library from "./Dashboard-pages/programmes/library/Library";

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

function NoMatch() {
  // let location = useLocation();

  return (
    <div>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited is not available yet."
        // extra={<Button type='primary'>Back Home</Button>}
      />
    </div>
  );
}

export default withRouter(function Dashboard({ history }) {
  let match = useRouteMatch();

  let user = localStorage.getItem("user");
  let currentRole = localStorage.getItem("current_role");

  let myRole = currentRole
    ? JSON.parse(currentRole)
        .officeID.replaceAll("1", "")
        .replaceAll("2", "")
        .replaceAll("3", "")
        .replaceAll("4", "")
        .replaceAll("5", "")
        .replaceAll("6", "")
        .replaceAll("7", "")
        .replaceAll("8", "")
        .replaceAll("9", "")
    : null;
  const [who, setwho] = useState(user ? JSON.parse(user) : {});
  const [roles, setroles] = useState(JSON.parse(who.roleId));
  const [sideWidth, setsideWidth] = useState(
    // window.innerWidth < 767 ? window.innerWidth * 0.8 :
    200
  );
  const [open, setOpen] = useState(false);
  const [defaultTabKey, setDefaultTabKey] = useState(
    myRole.includes("w")
      ? "0"
      : myRole.includes("r")
      ? "1"
      : myRole.includes("d")
      ? "2"
      : myRole.includes("z")
      ? "3"
      : "4"
  );

  const officeLevels = [
    { text: "weca", value: 0, key: "WECA" },
    { text: "regional", value: 1, key: "Regional" },
    { text: "district", value: 2, key: "District" },
    { text: "zonal", value: 3, key: "Zonal" },
    { text: "branch", value: 4, key: "Branch" },
  ];

  const [levels, setLevels] = useState({
    weca: [
      {
        id: "w1",
        office: "Youth Leader",
      },
      {
        id: "w2",
        office: "Deputy Youth Leader",
      },
      {
        id: "w3",
        office: "HOD Outreach",
      },
      {
        id: "w4",
        office: "Assitant HOD Outreach",
      },
      {
        id: "w5",
        office: "HOD Secretariat",
      },
      {
        id: "w6",
        office: "Assistant HOD Secretariat",
      },
      {
        id: "w7",
        office: "HOD Strategy And Planning",
      },
      {
        id: "w8",
        office: "Assitant HOD Strategy And Planning",
      },
    ],
    region: [
      {
        id: "r1",
        office: "Youth Head",
      },
      {
        id: "r2",
        office: "Youth Leader",
      },
      {
        id: "r3",
        office: "HOD Outreach",
      },
      {
        id: "r4",
        office: "HOD Secretariat",
      },
      {
        id: "r5",
        office: "HOD Strategy And Planning",
      },
      {
        id: "r6",
        office: "HOD Care And Empowerment",
      },
    ],
    district: [
      {
        id: "d1",
        office: "Youth Head",
      },
      {
        id: "d2",
        office: "Youth Leader",
      },
      {
        id: "d3",
        office: "HOD Outreach",
      },
      {
        id: "d4",
        office: "HOD Secretariat",
      },
      {
        id: "d5",
        office: "HOD Care And Empowerment",
      },
    ],
    zone: [
      {
        id: "z1",
        office: "Youth Leader",
      },
      {
        id: "z2",
        office: "HOD Secretariat",
      },
      {
        id: "z3",
        office: "HOD Care And Empowerment",
      },
    ],
    branch: [
      {
        id: "b1",
        office: "Youth Leader",
      },
      {
        id: "b2",
        office: "HOD Secretariat",
      },
      {
        id: "b3",
        office: "HOD Care And Empowerment",
      },
    ],
  });

  const [afcfLevels, setAfcfLevels] = useState({
    national: [
      {
        id: "ana1",
        office: "National President",
      },
      {
        id: "ana2",
        office: "National Secretary",
      },
    ],
    state: [
      {
        id: "ast1",
        office: "State President",
      },
      {
        id: "ast2",
        office: "State Secretary",
      },
    ],
    school: [
      {
        id: "asc1",
        office: "Coordinator",
      },
      {
        id: "asc2",
        office: "General Secretary",
      },
    ],
  });

  useEffect(() => {
    window.innerWidth < 767 && setOpen(true);
    let roles_ = [];
    for (let role of roles) {
      if (
        !roles_.find(
          (role_) =>
            // role_.officeID === role.officeID &&
            role.level_ === role_.level_ && role.levelName === role_.levelName
        )
      ) {
        roles_.push(role);
      }
    }
    setroles(roles_);
  }, [match, window.location]);

  useEffect(() => {
    getLevels();

    apiServices
      .fetchUsers()
      .then((res) => {
        localStorage.setItem("afm_members", JSON.stringify(res.data));
      })
      .catch((err) => {
        console.log({ err });
      });

    apiServices
      .fetchChurches_()
      .then((res) => {
        let branches = res.data.data.branches
          .map((z) => Object.values(z)[0])
          .reduce((a, b) => a.concat(b), [])
          .map((b) => {
            return {
              ...b,
              label: b.name,
              value: b.churchId,
            };
          });

        let zones = res.data.data.zones
          .map((z) => {
            return Object.values(z)[0];
          })
          .reduce((a, b) => a.concat(b), [])
          .map((zz) => {
            return {
              ...zz,
              label: zz.name,
              value: zz.name,
            };
          });

        let districts = res.data.data.districts
          .map((z) => Object.values(z)[0])
          .reduce((a, b) => a.concat(b), [])
          .map((b) => {
            return {
              ...b,
              label: b.name,
              value: b.name,
            };
          });

        let regions = res.data.data.regions.map((r) => {
          return {
            ...r,
            label: r.name,
            value: r.name,
          };
        });
        let payload = {
          branches,
          zones,
          districts,
          regions,
        };
        localStorage.setItem("afm_churches", JSON.stringify(payload));
      })
      .catch((err) => {
        console.log({ err });
      });
  }, []);

  const getLevels = () => {
    apiServices
      .fetchOfficerLevels()
      .then((res) => {
        setLevels(res.data.data);
      })
      .catch((err) => {
        console.log({ err });
      });

    apiServices
      .fetchAfcfOfficerLevels()
      .then((res) => {
        console.log(res.data);
        setAfcfLevels(res.data.data);
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  const logout = () => {
    history.push("/login");
    localStorage.clear();
  };

  const menu = (
    <Menu>
      {roles.map((role, i) => (
        <Menu.Item
          onClick={() => {
            localStorage.setItem("current_role", JSON.stringify(role));
            history.push("/dashboard");
          }}
          key={`${i}`}
        >
          <span>
            {`${role.levelName.replaceAll("%20", " ")} (${role.level_})`}
            {JSON.stringify(role) === localStorage.getItem("current_role") ? (
              <CheckOutlined style={{ marginLeft: 10 }} />
            ) : null}
          </span>
        </Menu.Item>
      ))}
      <Menu.Item>
        <span
          onClick={() => logout()}
          style={{
            color: "red",
            cursor: "pointer",
          }}
        >
          <span className="">logout</span> <LogoutOutlined />
        </span>
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        collapsed={open}
        // onBreakpoint={(broken) => {
        //   console.log(broken);
        //   // setsideWidth(window.innerWidth * 0.95);
        // }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
          zIndex: 100001,
        }}
      >
        <div className="logo" style={{ height: 30, backgroundColor: "#fff", margin: 15 }} />
        <Menu theme="dark" defaultSelectedKeys={["0"]} mode="inline">
          <Menu.Item key={"0"} icon={<PieChartOutlined />}>
            <Link to="/dashboard">Dashboard</Link>
          </Menu.Item>
          <SubMenu key="2" icon={<ClusterOutlined />} title="Structure">
            <Menu.Item key="3">
              <Link to="/dashboard/manage-structure">Manage Structure</Link>
            </Menu.Item>
            <Menu.Item key="4">
              <Link to={`/dashboard/structure?key=${defaultTabKey}`}>Assign Officers</Link>
            </Menu.Item>
            <Menu.Item key="41">
              <Link to={`/dashboard/afcf-structure?key=${defaultTabKey}`}>Assign Afcf Officers</Link>
            </Menu.Item>
            <Menu.Item key="5">
              <Link to="/dashboard/update-church">Update Church Info</Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu key="6" icon={<BuildOutlined />} title="Platforms">
            <Menu.Item key="6.1">
              <Link to="/dashboard/platforms/working">Working/Academic Demographics</Link>
            </Menu.Item>
            <Menu.Item key="6.2">
              <Link to="/dashboard/platforms/marital">Marital Demography</Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu key="7" icon={<BarsOutlined />} title="Programmes">
            <Menu.Item key="7.1">
              <Link to="/dashboard/programmes/library">Library</Link>
            </Menu.Item>
          </SubMenu>
          {/* <Menu.Item key={"7"} icon={<BarsOutlined />}>
            <Link to="/dashboard/programs">Programmes</Link>
          </Menu.Item> */}
          <Menu.Item key={"8"} icon={<CoffeeOutlined />}>
            <Link to="/dashboard/care">Care</Link>
          </Menu.Item>
        </Menu>

        <span
          onClick={() => logout()}
          style={{
            color: "red",
            cursor: "pointer",

            position: "absolute",
            bottom: 20,
            left: 20,
          }}
        >
          <span className="mob">logout</span> <LogoutOutlined />
        </span>
      </Sider>
      <Layout className="site-layout" style={{ marginLeft: !open ? sideWidth : 0 }}>
        <Header className="site-layout-background" style={{ padding: 0, backgroundColor: "#fff", width: "100%" }}>
          <div className="heading-wrap shadowed">
            <Dropdown overlay={menu} placement="topLeft">
              <a className="ant-dropdown-link user-name" onClick={(e) => e.preventDefault()}>
                <UserOutlined style={{ marginRight: 10 }} />
                {`${who.firstName} ${who.lastName}`}
              </a>
            </Dropdown>
            <MenuOutlined onClick={() => setOpen(!open)} />
          </div>
        </Header>
        <Content
          style={{
            margin: "0",
            overflow: "auto",
            backgroundColor: "#eff2f5",
            height: "100vh",
          }}
        >
          <div>
            <Switch match>
              <Route exact path={`${match.path}/`}>
                <DashboardHome />
              </Route>
              <Route path={`${match.path}/update-church`}>
                <UpdateChurch officeLevels={officeLevels} roles={levels} />
              </Route>
              <Route path={`${match.path}/manage-structure`}>
                <Structure />
              </Route>
              <Route path={`${match.path}/afcf-structure`}>
                <AfcfStructure officeLevels={officeLevels} levels={afcfLevels} />
              </Route>
              <Route path={`${match.path}/structure`}>
                <ManageStructure officeLevels={officeLevels} levels={levels} />
              </Route>
              <Route path={`${match.path}/assign-structure`}>
                <div style={{ padding: 30 }}>
                  <RegionTable officeLevels={officeLevels} roles={levels} />
                </div>
              </Route>
              <Route path={`${match.path}/platforms/:type`}>
                <Platforms />
              </Route>
              
              <Route path="/dashboard/programmes/library">
                <Library />
              </Route>

              <Route path="*">
                <NoMatch />
              </Route>
            </Switch>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
});
